// if: lines of code > 300
// then: breakdown this file and move to src/features/ExlyConnect/constants folder
import { app_pathnames, app_route_keys } from "constants/urlPaths";
import { EXLY_CONNECT_APIS } from "./ExlyConnect.api";

export const EXLY_CONNECT_TABS = {
  scheduled: {
    label: "Scheduled",
    value: "scheduled",
  },
  on_demand: {
    label: "On-demand",
    value: "ondemand",
  },
};

export const exly_connect_resource_apis = {
  [app_pathnames[app_route_keys.exly_connect](
    EXLY_CONNECT_TABS.scheduled.value
  )]: {
    api_end_point: EXLY_CONNECT_APIS.fetch_schedule,
  },
  [app_pathnames[app_route_keys.exly_connect](
    EXLY_CONNECT_TABS.on_demand.value
  )]: {
    api_end_point: EXLY_CONNECT_APIS.fetch_ondemand_schedule,
  },
};

export const EXLY_CONNECT_TITLE = "Exly Connect";

// Here, em refers to error message, we do not want to write unnecessary url params in the url
export const exly_connect_error_message_key = "em";

export const EXLY_CONNECT_LEARN_MORE_HREFS = {
  EXLY_CONNECT:
    "https://support.exlyapp.com/support/solutions/articles/84000379616-conduct-your-sessions-via-exly-connect-by-zoom",
  EXLY_CONNECT_VIDEO: "https://www.youtube-nocookie.com/embed/mV95y3DaLxc",
};
